import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DropDownSelectorValues,
  MAIN_PACE_TASK_IDs,
} from '@symfa-inc/providence-types';
import { Col, Form, Row } from 'antd';
import { momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import { YES_NO_GROUP } from '@models/constants';
import { EngineeringRegulatoryCompliance, FormProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors, PaceTasksSelectors } from '@store/selectors';
import { REGULATORY_COMPLIANCE_INFO_DATES } from '../../models/constants';

export const RegulatoryCompliancePanel: FC<FormProps> = ({
  form,
  isEditing,
  data: { SOWNote, ...data },
  permissions: {
    SOWNote: SOWNotePermissions,
    SOWSubmitDate,
    EHSApprovedDate,
    isEHSRequired,
    FAAFileDate,
    RFSSRVendor,
    SOWRejectedDate,
    craneHeight,
    EHSSubmittedDate,
    CNTPReceivedDate,
    escalateDate,
    FAACaseNumber,
    isRFSSRRequired,
    isCraneRequired,
    PALOrNPAReceived,
    PALOrNPARequired,
    RFSSROrdered,
    RFSSRReceived,
    determinationDate,
  },
  onValuesChange,
}: PropsWithChildren<FormProps>) => {
  const PALOrNPARequiredDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(
      DropDownSelectorValues.PALNPARequired,
    ),
  );
  const RFSSRVendorDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(
      DropDownSelectorValues.RFSSRVendor,
    ),
  );
  // PTP - PaceTask permission
  const [RFSSROrderedPTP, RFSSRReceivedPTP] = useSelector(
    PaceTasksSelectors.getPaceTasksPermissions([
      {
        PACETaskId: MAIN_PACE_TASK_IDs.RFSSROrdered,
        dateType: 'actualDate',
      },
      {
        PACETaskId: MAIN_PACE_TASK_IDs.RFSSRReceived,
        dateType: 'actualDate',
      },
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<EngineeringRegulatoryCompliance>(
          data as EngineeringRegulatoryCompliance,
          REGULATORY_COMPLIANCE_INFO_DATES,
        ),
      );
    }
  }, [data, isEditing]);

  return (
    <Panel
      header="Regulatory/Compliance"
      collapsible
      className="prov-engineering-workflow__panel"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="10">
            <DatePicker
              id="SOWSubmitDate"
              label="SOW Submit"
              isEditing={isEditing && !!SOWSubmitDate}
            />
            <DatePicker
              id="SOWRejectedDate"
              label="SOW Rejected"
              isEditing={isEditing && !!SOWRejectedDate}
            />
            <ShowMoreInput
              id="SOWNote"
              label="SOW Notes"
              data={SOWNote}
              form={form}
              isEditing={isEditing && !!SOWNotePermissions}
            />
            <DatePicker
              id="CNTPReceivedDate"
              label="CNTP Received"
              isEditing={isEditing && !!CNTPReceivedDate}
            />
            <RadioGroup
              id="isCraneRequired"
              label="Crane Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isCraneRequired}
            />
            <InputNumber
              id="craneHeight"
              label="Crane Height"
              isEditing={isEditing && !!craneHeight}
            />
            <Input
              id="FAACaseNumber"
              label="FAA Case Number"
              isEditing={isEditing && !!FAACaseNumber}
            />
            <DatePicker
              id="FAAFileDate"
              label="FAA File Date"
              isEditing={isEditing && !!FAAFileDate}
            />
            <DatePicker
              id="escalateDate"
              label="Escalate Date"
              isEditing={isEditing && !!escalateDate}
            />
            <DatePicker
              id="determinationDate"
              label="Determination Date"
              isEditing={isEditing && !!determinationDate}
            />
          </Col>
          <Col span="12">
            <RadioGroup
              id="isEHSRequired"
              label="EHS Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isEHSRequired}
            />
            <DatePicker
              id="EHSSubmittedDate"
              label="EHS Submitted"
              isEditing={isEditing && !!EHSSubmittedDate}
            />
            <DatePicker
              id="EHSApprovedDate"
              label="EHS Approved"
              isEditing={isEditing && !!EHSApprovedDate}
            />
            <RadioGroup
              id="isRFSSRRequired"
              label="RFSSR Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isRFSSRRequired}
            />
            <DatePicker
              id="RFSSROrdered"
              label="RFSSR Ordered Date"
              isEditing={isEditing && !!RFSSROrdered && RFSSROrderedPTP}
              formItemProps={{
                rules: ACTUAL_DATE(data?.RFSSROrdered),
              }}
            />
            <DatePicker
              id="RFSSRReceived"
              label="RFSSR Received Date"
              isEditing={isEditing && !!RFSSRReceived && RFSSRReceivedPTP}
              formItemProps={{
                rules: ACTUAL_DATE(data?.RFSSRReceived),
              }}
            />
            <Autocomplete
              id="RFSSRVendor"
              label="RFSSR Vendor"
              isEditing={isEditing && !!RFSSRVendor}
              options={RFSSRVendorDDV}
            />
            <Autocomplete
              id="PALOrNPARequired"
              label="PAL/NPA Required"
              isEditing={isEditing && !!PALOrNPARequired}
              options={PALOrNPARequiredDDV}
            />
            <DatePicker
              id="PALOrNPAReceived"
              label="PAL/NPA Received"
              isEditing={isEditing && !!PALOrNPAReceived}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
