import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Form } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import { FormProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { DatePicker } from '@shared/modules';
import { ZONING_INFO_DATES, ZoningSection } from '../../models';

import './styles.scss';

export const WorkflowZoningSection: FC<FormProps> = ({
  isEditing,
  onValuesChange,
  permissions: {
    BPReceived,
    BPSubmitted,
    BPExpiration,
    zoningAppDeemedComplete,
    zoningApproved,
    zoningSubmitted,
  },
  data,
  form,
}: PropsWithChildren<FormProps>) => {
  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<ZoningSection>(data, ZONING_INFO_DATES),
      );
    }
  }, [data]);

  return (
    <Panel header="Zoning" className="prov-workflow-zoning-section" collapsible>
      <Form
        className="prov-workflow-zoning-section__form"
        labelCol={{ span: 10 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <DatePicker
          id="zoningSubmitted"
          label="Zoning Submitted"
          isEditing={canBeEditable(zoningSubmitted, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.zoningSubmitted),
          }}
        />
        <DatePicker
          id="zoningApproved"
          label="Zoning Approved"
          isEditing={canBeEditable(zoningApproved, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.zoningApproved),
          }}
        />
        <DatePicker
          id="BPReceived"
          label="BP Received"
          isEditing={canBeEditable(BPReceived, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.BPReceived),
          }}
        />
        <DatePicker
          id="zoningAppDeemedComplete"
          label="Zoning App Deemed Complete"
          isEditing={canBeEditable(zoningAppDeemedComplete, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.zoningAppDeemedComplete),
          }}
        />
        <DatePicker
          id="BPSubmitted"
          label="BP Submitted"
          isEditing={canBeEditable(BPSubmitted, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.BPSubmitted),
          }}
        />
        <DatePicker
          id="BPExpiration"
          label="BP Expiration Date"
          isEditing={canBeEditable(BPExpiration, isEditing)}
          formItemProps={{}}
        />
      </Form>
    </Panel>
  );
};
