import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { YES_NO_GROUP } from '@models/constants';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Coordinate,
  Input,
  PrimaryButton,
  RadioGroup,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

const { useForm } = Form;

export const SiteInformation: FC<InformationProps> = ({
  className,
  data: { siteNote, ...data },
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      const {
        state,
        county,
        jurisdiction,
        towerType,
        equipmentType,
        generatorInstalled,
        ...restData
      } = data;

      form.setFieldsValue({
        ...restData,
        state: state?.name,
        county: county?.name,
        jurisdiction: jurisdiction?.jurisdictionName,
        towerType: towerType?.value,
        equipmentType: equipmentType?.value,
        generatorInstalled: generatorInstalled?.value,
      });
    }
  }, [data]);

  return (
    <Panel header="Site Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="FALocationNumber"
              label="FA Location Number"
              isEditing={false}
            />
            <Input id="siteNumber" label="Site Number" isEditing={false} />
            <Input id="legacySiteID" label="Legacy Site ID" isEditing={false} />
            <Autocomplete
              id="towerOwner"
              label="Tower Owner"
              options={dropdownsOptions?.TowerOwner}
              isEditing={false}
            />
            <Input
              id="towerOwnerSiteID"
              label="Tower Owner Site ID"
              isEditing={false}
            />
            <Input id="USID" label="USID" isEditing={false} />
            <RadioGroup
              id="isHighRisk"
              label="High Risk Site"
              buttons={YES_NO_GROUP}
              isEditing={false}
            />
            <Autocomplete
              id="riskCategory"
              label="Risk Category"
              options={dropdownsOptions?.RiskCategory}
              isEditing={false}
            />
            <ShowMoreInput
              id="siteNote"
              label="Site Notes"
              data={siteNote}
              form={form}
              isEditing={false}
            />
            <Input id="towerType" label="Tower Type" isEditing={false} />
            <Input
              id="towerHeight"
              label="Tower Height (ft)"
              isEditing={false}
            />
            <Input
              id="equipmentType"
              label="Equipment Type"
              isEditing={false}
            />
            <Input
              id="generatorInstalled"
              label="Generator Installed"
              isEditing={false}
            />
            <Input
              id="sqInchesEquipmentAllowed"
              label="Sq. Inches Equipment Allowed"
              isEditing={false}
            />
            <Input
              id="sqInchesEquipmentUsed"
              label="Sq. Inches Equipment Used"
              isEditing={false}
            />
            <Input
              id="sqInchesCablesAllowed"
              label="Sq. Inches Cables Allowed"
              isEditing={false}
            />
            <Input
              id="sqInchesCablesUsed"
              label="Sq. Inches Cables Used"
              isEditing={false}
            />
          </Col>
          <Col span="12">
            <Input
              id="streetAddress"
              label="Street Address"
              isEditing={false}
            />
            <Input id="city" label="City" isEditing={false} />
            <Input id="state" label="State" isEditing={false} />
            <Input id="zip" label="Zip" isEditing={false} />
            <Input id="county" label="County" isEditing={false} />
            <Input id="jurisdiction" label="Jurisdiction" isEditing={false} />
            <Coordinate id="latitude" label="Latitude" isEditing={false} />
            <Coordinate id="longitude" label="Longitude" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/site/${expectedId}/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
