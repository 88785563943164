import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { EditableInformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Input,
  PrimaryButton,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

export const ProjectInformation: FC<EditableInformationProps> = ({
  className,
  data: { projectNote, ...data },
  expectedId,
  isEditing,
  form,
  onValuesChange,
  permissions: {
    projectBundleID,
    projectType,
    projectStatus,
    siteAcqVendor,
    civilVendor,
    projectNote: projectNotePermission,
    AEMountVendor,
    AEVendor,
  },
}: PropsWithChildren<EditableInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue({
        ...data,
      });
    }
  }, []);

  return (
    <Panel header="Project Information" className={className}>
      <Form
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 14 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="12">
            <Input
              id="projectBundleID"
              label="Project Bundle ID"
              isEditing={isEditing && !!projectBundleID}
            />
            <Autocomplete
              id="projectType"
              label="Project Type"
              isEditing={isEditing && !!projectType}
              options={dropdownsOptions?.ProjectType}
            />
            <Autocomplete
              id="projectStatus"
              label="Project Status"
              isEditing={isEditing && !!projectStatus}
              options={dropdownsOptions?.ProjectStatus}
            />
            <Autocomplete
              id="civilVendor"
              label="Civil Vendor"
              isEditing={isEditing && !!civilVendor}
              options={dropdownsOptions?.CivilVendor}
            />
          </Col>
          <Col span="12">
            <Autocomplete
              id="siteAcqVendor"
              label="Site Acq Vendor"
              isEditing={isEditing && !!siteAcqVendor}
              options={dropdownsOptions?.SiteACQVendor}
            />
            <Autocomplete
              id="AEVendor"
              label="A/E CD Vendor"
              isEditing={isEditing && !!AEVendor}
              options={dropdownsOptions?.AEVendor}
            />
            <Autocomplete
              id="AEMountVendor"
              label="A/E Mount Vendor"
              isEditing={isEditing && !!AEMountVendor}
              options={dropdownsOptions?.AEMountVendor}
            />
            <ShowMoreInput
              id="projectNote"
              label="Project Notes"
              isEditing={isEditing && !!projectNotePermission}
              data={projectNote}
              form={form}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/project/${expectedId}/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
