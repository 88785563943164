import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  arrsToSelectableObject,
  canBeEditable,
  normalize,
  phoneElementRender,
} from '@core/utils/methods';
import { isPhoneNumber } from '@core/utils/validators';
import { INPUT_POSITIVE_NUMBER_VALIDATOR } from '@models/constants';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Input,
  InputNumber,
  PhoneNumber,
  ShowMoreInput,
} from '@shared/modules';
import { CountySelectors } from '@store/selectors';
import {
  getJurisdictionNameValidationRules,
  linkElementRender,
} from '../../helpers';
import { JurisdictionSummarySectionProps } from '../../models';

import './styles.scss';

export const JurisdictionSummarySection: FC<
  JurisdictionSummarySectionProps
> = ({
  isEditing,
  permissions: {
    city,
    jurisdictionContactEmail,
    jurisdictionContactFax,
    jurisdictionContactName,
    jurisdictionContactPhone,
    jurisdictionName,
    jurisdictionNote: jurisdictionNotePermissions,
    linkToOnlineSubmittal,
    linkToOrdinance,
    mailingStreetAddress,
    state,
    zip,
  },
  updateSummarySection,
  data: { jurisdictionNote, ...data },
  form,
  jurisdictionId,
}: PropsWithChildren<JurisdictionSummarySectionProps>) => {
  const [currentState, setCurrentState] =
    useState<Undefinable<string>>(undefined);

  const rules = useMemo(
    () =>
      getJurisdictionNameValidationRules(
        jurisdictionId,
        currentState || data.state,
      ),
    [jurisdictionId, currentState, data],
  );

  const states = useSelector(CountySelectors.getStatesWithCounties);

  useEffect(() => {
    form?.setFieldsValue(data);
  }, [isEditing]);

  useDidUpdateEffect(() => {
    form?.validateFields(['jurisdictionName']);
  }, [currentState]);

  return (
    <Panel
      header="Summary"
      className="prov-jurisdiction-summary-section"
      collapsible
    >
      <Form
        className="prov-jurisdiction-summary-section__form"
        form={form}
        labelCol={{ span: 10 }}
        onValuesChange={updateSummarySection}
      >
        <Input
          id="jurisdictionName"
          className="left with-validation"
          label="Jurisdiction Name"
          isEditing={canBeEditable(jurisdictionName, isEditing)}
          formItemProps={{
            rules,
          }}
        />
        <PhoneNumber
          id="jurisdictionContactFax"
          className="right with-validation"
          label="Jurisdiction Contact Fax"
          formItemProps={{
            rules: [isPhoneNumber()],
          }}
          isEditing={canBeEditable(jurisdictionContactFax, isEditing)}
          readOnlyElementRender={phoneElementRender}
        />
        <Input
          id="mailingStreetAddress"
          className="left"
          label="Mailing Street Address"
          isEditing={canBeEditable(mailingStreetAddress, isEditing)}
        />
        <Input
          id="jurisdictionContactEmail"
          className="right"
          label="Jurisdiction Contact Email"
          isEditing={canBeEditable(jurisdictionContactEmail, isEditing)}
        />
        <Input
          id="city"
          className="left"
          label="City"
          isEditing={canBeEditable(city, isEditing)}
        />
        <Input
          id="linkToOrdinance"
          className="right"
          label="Link to Ordinance"
          isEditing={canBeEditable(linkToOrdinance, isEditing)}
          readOnlyElementRender={linkElementRender}
        />
        <Autocomplete
          id="state"
          className="left"
          label="State"
          options={arrsToSelectableObject(states, 'id', 'name')}
          isEditing={canBeEditable(state, isEditing)}
          elementProps={{
            onChange: (v: SelectValue): void => setCurrentState(v as string),
          }}
          formItemProps={{
            rules,
          }}
        />
        <Input
          id="linkToOnlineSubmittal"
          className="right"
          label="Link to Online Submittal"
          isEditing={canBeEditable(linkToOnlineSubmittal, isEditing)}
          readOnlyElementRender={linkElementRender}
        />
        <InputNumber
          id="zip"
          className="left"
          label="Zip"
          elementProps={{ min: 1 }}
          isEditing={canBeEditable(zip, isEditing)}
          formItemProps={{
            normalize,
            rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
          }}
        />
        <ShowMoreInput
          id="jurisdictionNote"
          className="right text-area"
          label="Jurisdiction Notes"
          data={jurisdictionNote}
          form={form}
          isEditing={canBeEditable(jurisdictionNotePermissions, isEditing)}
        />
        <Input
          id="jurisdictionContactName"
          className="left"
          label="Jurisdiction Contact Name"
          isEditing={canBeEditable(jurisdictionContactName, isEditing)}
        />
        <PhoneNumber
          id="jurisdictionContactPhone"
          className="left with-validation"
          label="Jurisdiction Contact Phone"
          formItemProps={{
            rules: [isPhoneNumber()],
          }}
          isEditing={canBeEditable(jurisdictionContactPhone, isEditing)}
          readOnlyElementRender={phoneElementRender}
        />
      </Form>
    </Panel>
  );
};
